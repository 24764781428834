<template>
  <p-container class="detail-container" :showBack="true">
    <a-collapse :activeKey="[1, 2, 3]" :bordered="false">
      <a-collapse-panel key="1" header="基本信息">
        <a-descriptions bordered size="small" :column="2">
          <a-descriptions-item :label="d.label" v-for="d in data" :key="d.key" :span="d.span||1">
            {{ d.value }}
          </a-descriptions-item>
        </a-descriptions>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="项目信息"> </a-collapse-panel>
      <a-collapse-panel key="3" header="产品信息"> </a-collapse-panel>
    </a-collapse>
  </p-container>
</template>

<script>
const data=[{
  key: 'title', label: '专利名称', value: '养生型合成石及其制品'
},{
  key: 'appNo', label: '申请号', value: '201510287212.7'
},{
  key: 'type', label: '申请类型', value: '发明'
},{
  key: 'num', label: '权利要求数', value: '0'
},{
  key: 'page', label: '专利全文页数', value: '10'
},{
  key: 'ano', label: '申请日期', value: '2015-05-29'
},{
  key: 'c', span: 2, label: '摘要', value: '本发明涉及一种永磁同步电机转子零位校正系统及方法，属于电机伺服控制技术领域。本发明首先将电机控制支路中q轴电流设为0，d轴电流控制在1.2倍额定电流值以内，然后通过给定矢量控制中使用的角度从30°逐步减小至0°，以拖动永磁同步电机转子由θ角至理论零位；判断永磁同步电机转子是否达到最终稳定位置，以永磁同步电机转子最终稳定位置时的角度作为转子零位校正角度；最后将得到校正角度补偿到电机控制过程测量到的转子角度作为实际的转子角度，从而实现对转子零位的校正。本发明的校正方法简单易实施，能够很好的解决现有永磁同步电机转子零位校正所存在的操作麻烦、通用性差以及相电流大小不易控制的问题。  '
},{
  key: 'd', label: '所属单位', value: '许继集团有限公司'
}]
export default {
  data(){
    return {
      data
    }
  }
};
</script>

<style lang="scss" scoped>

</style>